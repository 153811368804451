<template>
  <div class="contentCnfig">
    <div class="entete">
      <div class="box-setting-header ">
        <div class="title">Gestion organisation par semaine</div>
        <b-button
          variant="success"
          class="config-btn ml-2"
          v-b-modal.ConfigurationOrganisme
          ><font-awesome-icon icon="plus" /> Nouvelle configuration</b-button
        >
        <b-modal
          id="ConfigurationOrganisme"
          ref="ConfigurationOrganisme"
          title="Nouvelle configuration"
          no-close-on-backdrop
          :hide-footer="true"
          :hide-header="true"
          @show="resetModal"
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap"
        >
          <div class="hader mb-3">
            <div class="titleSetting">Nouvelle configuration</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('ConfigurationOrganisme')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="ConfigurationOrganisme"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Organisation"
                  label-for="Organisation"
                  class="vue-select-modal-champ "
                >
                  <multiselect
                    v-model="organisation.id"
                    placeholder="Rechercher"
                    track-by="name"
                    label="name"
                    :options="getAllorganisations"
                    :searchable="true"
                    required
                    :showLabels="false"
                    class="select-vue-component-style "
                  >
                    <span slot="noResult">Aucun organisme trouvé </span>
                    <span slot="noOptions">La liste est vide</span>
                  </multiselect>
                </b-form-group>
                <b-form-group
                  label="Année"
                  label-for="year"
                  class="input-modal-champ"
                >
                  <b-form-select
                    id="year"
                    v-model="yearConfig"
                    :options="ListYear"
                    required
                    @change="handleChangeYearModel"
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Semaine"
                  label-for="semaine"
                  class="input-modal-champ"
                >
                  <b-form-select
                    id="semaine"
                    v-model="organisation.semaine"
                    :options="ListWeek"
                    required
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Coef CA"
                  label-for="prix"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="prix"
                    v-model="organisation.prix"
                    required
                  ></b-form-input>
                </b-form-group>

                <div class="actionModel">
                  <div class="messageError mt-3">
                    <div v-if="getErrorOrganisme" class="error">
                      {{ getErrorOrganisme }}
                    </div>
                  </div>

                  <b-button type="submit" class="button-valide-style ">
                    <span>
                      Valider
                      <div v-if="getloagingOrganisme" class="loading ml-2">
                        <div class="spinner-border" role="status"></div></div
                    ></span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
      <div class="ligne-box-setting-header"></div>
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style"
            v-model="filterSemaine"
            :options="ComputedListWeek"
            @change="handleChange"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style w-1">
            <span class="title-tabel ">Organisation</span>
          </div>

          <b-form-select
            class="b-form-select-new-style"
            v-model="filterOrganisation"
            :options="ComputedListOrganisation"
            value-field="id"
            text-field="name"
            @change="handleChange"
          ></b-form-select>
        </div>

        <div class="box-end-position"></div>
      </div>
    </div>

    <div>
      <b-table
        show-empty
        id="my-table"
        class="custom-table-style configuration-organnisme-semaine-style"
        :items="computedgetorganisationsConfig"
        :fields="fields"
        bordered
        sticky-header
        hover
        head-variant="light"
        empty-text="Il n'y a aucun enregistrement à afficher"
        tbody-tr-class="ligneNormale"
      >
        <template v-slot:cell(Actions)="data">
          <b-button
            class="button-succes-style ml-2"
            size="sm"
            variant="success"
            @click.prevent.stop="handleUpdate(data.item)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </b-button>
        </template>
      </b-table>
      <div class="footer-style mt-2">
        <b-pagination
          v-model="page"
          :total-rows="getTotalRowOrganisme"
          :per-page="perPage"
          aria-controls="my-table"
          pills
          align="center"
          size="sm"
          @change="pagination"
          class="pagination-style"
        ></b-pagination>
      </div>
    </div>
    <b-modal
      id="ModelConfigOrganisme"
      ref="ModelConfigOrganisme"
      title="Configuration"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-3">
        <div class="titleSetting">Configuration</div>

        <div
          class="iconClose"
          @click.prevent="hideModal('ModelConfigOrganisme')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="ConfigurationOrganismeNew"
            v-if="OrganismeToConfig"
            class="form-modal-custom-style"
          >
            <b-form-group
              label="Organisation"
              label-for="Organisation"
              class="input-modal-champ"
            >
              <b-form-select
                id="Organisation"
                v-model="OrganismeToConfig.id"
                :options="getAllorganisations"
                value-field="id"
                text-field="name"
                :disabled="true"
                class="b-form-select-raduis "
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label="Semaine"
              label-for="semaine"
              class="input-modal-champ"
            >
              <b-form-select
                id="semaine"
                v-model="OrganismeToConfig.semaine"
                :options="ListWeek"
                :disabled="true"
                class="b-form-select-raduis "
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label="Année"
              label-for="year"
              class="input-modal-champ"
            >
              <b-form-select
                id="year"
                v-model="filteryear"
                :options="ListYear"
                :disabled="true"
                class="b-form-select-raduis "
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label="Coef CA"
              label-for="prix"
              class="input-modal-champ"
            >
              <b-form-input
                autocomplete="off"
                id="prix"
                v-model="OrganismeToConfig.prix"
                required
              ></b-form-input>
            </b-form-group>

            <div class="actionModel">
              <div class="messageError mt-3">
                <div v-if="getErrorOrganisme" class="error">
                  {{ getErrorOrganisme }}
                </div>
              </div>

              <b-button class="button-valide-style " type="submit">
                <span>
                  Valider
                  <div v-if="getloagingOrganisme" class="loading ml-2">
                    <div class="spinner-border" role="status"></div></div
                ></span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data() {
    return {
      OrganismeToConfig: null,
      page: 1,
      perPage: 10,
      yearConfig: null,
      filterOrganisation: null,
      filteryear: null,
      filterSemaine: null,
      organisation: {
        name: null,
        id: null,
        prix: null,
        semaine: null
      },
      ListYear: [],
      ListWeek: [],
      ListOrganisme: [],
      fields: [
        { key: 'name', label: 'Nom' },
        { key: 'prix', label: 'Coef CA' },
        { key: 'semaine', label: 'Semaine' },
        { key: 'Actions', label: 'Actions' }
      ]
    };
  },
  methods: {
    ...mapMutations(['SET_ERROR_ORGANISME']),
    ...mapActions([
      'configOrganismes',
      'fetchOrganismeFiltred',
      'getAllOrganisme'
    ]),
    resetModal() {
      this.organisation.name = null;
      this.organisation.id = null;
      this.organisation.semaine = null;
      this.organisation.prix = null;
      this.yearConfig = null;
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleChange() {
      localStorage.setItem(
        'Organisation-Filters',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterOrganisation: this.filterOrganisation
        })
      );
      this.page = 1;
      sessionStorage.setItem('page-organisation', this.page);
      this.fetchOrganismeFiltred({
        page: this.page,
        id: this.filterOrganisation,
        semaine: this.filterSemaine,
        annee: this.filteryear
      });
    },
    handleUpdate(Organisme) {
      this.OrganismeToConfig = { ...Organisme };
      this.$refs['ModelConfigOrganisme'].show();
    },
    async ConfigurationOrganismeNew() {
      const response = await this.configOrganismes({
        organisation: this.OrganismeToConfig,
        annee: this.filteryear
      });
      if (response) {
        this.hideModal('ModelConfigOrganisme');
      }
    },
    async ConfigurationOrganisme() {
      // this.getAllorganisations.map(organisation => {
      //   if (organisation.id == this.organisation.id) {
      //     this.organisation.name = organisation.name;
      //   }
      // });
      if (this.organisation.id) {
        const organismeSelected = this.organisation.id;
        this.organisation.id = organismeSelected.id;
        this.organisation.name = organismeSelected.name;
        const response = await this.configOrganismes({
          organisation: this.organisation,
          annee: this.yearConfig
        });
        if (response) {
          this.hideModal('ConfigurationOrganisme');
        }
      }
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-organisation', this.page);
      this.fetchOrganismeFiltred({
        page: this.page,
        id: this.filterOrganisation,
        semaine: this.filterSemaine,
        annee: this.filteryear
      });
    },
    handleChangeYear() {
      var Filters = JSON.parse(localStorage.getItem('Organisation-Filters'));
      Filters.filteryear = this.filteryear;
      localStorage.setItem('Organisation-Filters', JSON.stringify(Filters));
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      this.page = 1;
      sessionStorage.setItem('page-organisation', this.page);
      this.fetchOrganismeFiltred({
        id: this.filterOrganisation,
        semaine: this.filterSemaine,
        annee: this.filteryear,
        page: this.page
      });
    },
    handleChangeYearModel() {
      var totalWeek = moment({ year: this.yearConfig }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect'),
    Card: () => import('../component/card')
  },
  mounted() {
    var courantYear = moment().year();
    if (localStorage.getItem('Organisation-Filters')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('Organisation-Filters')
      ).filteryear;
      this.filterSemaine = JSON.parse(
        localStorage.getItem('Organisation-Filters')
      ).filterSemaine;
      this.filterOrganisation = JSON.parse(
        localStorage.getItem('Organisation-Filters')
      ).filterOrganisation;
    } else {
      this.filteryear = courantYear;
      this.filterSemaine = 's' + moment().isoWeek();
      localStorage.setItem(
        'Organisation-Filters',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterOrganisation: this.filterOrganisation
        })
      );
    }
    if (sessionStorage.getItem('page-organisation')) {
      this.page = sessionStorage.getItem('page-organisation');
    } else {
      sessionStorage.setItem('page-organisation', this.page);
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    this.SET_ERROR_ORGANISME(null);
    this.fetchOrganismeFiltred({
      id: this.filterOrganisation,
      semaine: this.filterSemaine,
      annee: this.filteryear,
      page: this.page
    });
    this.getAllOrganisme();
  },

  computed: {
    ...mapGetters([
      'getOrganisations',
      'getorganisationsConfig',
      'getTotalRowOrganisme',
      'getloagingOrganisme',
      'getErrorOrganisme',
      'getAllorganisations'
    ]),
    ComputedListWeek() {
      return [{ value: null, text: 'tous' }, ...this.ListWeek];
    },
    ComputedListOrganisation() {
      return [{ id: null, name: 'tous' }, ...this.getAllorganisations];
    },
    computedgetorganisationsConfig() {
      if (this.filterSemaine === null && this.filterOrganisation !== null) {
        return this.getorganisationsConfig.filter(
          row => row.id == this.filterOrganisation
        );
      } else if (
        this.filterSemaine !== null &&
        this.filterOrganisation === null
      ) {
        return this.getorganisationsConfig.filter(
          row => row.semaine == this.filterSemaine
        );
      } else if (
        this.filterSemaine !== null &&
        this.filterOrganisation !== null
      ) {
        return this.getorganisationsConfig.filter(
          row =>
            row.semaine === this.filterSemaine &&
            row.id === this.filterOrganisation
        );
      }

      return this.getorganisationsConfig;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.contentCnfig {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;

  .w-1 {
    width: 100px !important;
  }
}
.configuration-organnisme-semaine-style {
  max-height: calc(100vh - 247px) !important;
  height: calc(100vh - 247px) !important;
  margin-bottom: 0px;
}
</style>
<style lang="scss">
.configuration-organnisme-semaine-style {
  td,
  th {
    padding: 5px !important;
    min-width: 230px;
  }
}
</style>
